import React, { useContext, useEffect, useState } from "react";
import upload_icon from "./images/upload_icon.png";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";

export default function Settings() {
  const { userDetails, userUpdateHanlder, profileUpdateHandler } =
    useContext(UserContext);
  const [fields, setFields] = useState({
    name: userDetails.name,
    mobile: userDetails.mobile,
    gender: userDetails.gender,
    address: userDetails.address,
    profile_pic: userDetails.profile_pic,
  });
  console.log(userDetails)
  
  const navigate = useNavigate();

  const nameHandler = (name) => {
    setFields((prev) => {
      return { ...prev, name };
    });
  };

  const mobileHandler = (mobile) => {
    setFields((prev) => {
      return { ...prev, mobile };
    });
  };

  const addressHandler = (address) => {
    setFields((prev) => {
      return { ...prev, address };
    });
  };

  const genderHandler = (gender) => {
    setFields((prev) => {
      return { ...prev, gender };
    });
  };

  const formdata = new FormData();
  formdata.append("name", fields.name);
  formdata.append("gender", fields.gender);
  formdata.append("address", fields.address);
  formdata.append("mobile", fields.mobile);
  formdata.append("user_id", fields.id);

  const userForm = new FormData();
  userForm.append("user_id", fields.id);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    userForm.append("img", file);
    profileUpdateHandler(userForm);
  };

  useEffect(() => {
    setFields(userDetails);
  }, [userDetails]);

  const handleUpdate = () => {
    userUpdateHanlder(formdata);
    navigate("/")
  }

  return (
    <>
      <div className="main">
        <h4>Account Setting</h4>
        <hr />
        <h5>Your Profile Picture</h5>
        <div className="fileUploaderWrapper">
          {userDetails.profile_pic ? (
            <input
              className="imageWrapper"
              type="image"
              src={`https://useind.com/admin/assets/uploads/${userDetails.profile_pic}`}
              alt="img"
            />
          ) : (
            <input type="image" src={upload_icon} alt="img" />
          )}
          <input
            className="imageUploader"
            onChange={handleFileChange}
            type="file"
            name="img"
          />
        </div>
        <hr />
        <div className="name_mail">
          <div class="div_1">
            <label for="" class="form-label">
              Full Name
            </label>
            <input
              type="text"
              value={fields.name}
              class="form-control"
              placeholder="Please enter your full name"
              onChange={(e) => nameHandler(e.target.value)}
            />
          </div>
          <div class="div_1">
            <label for="" class="form-label">
              Phone number
            </label>
            <input
              type="text"
              value={fields.mobile}
              class="form-control"
              placeholder="Please enter your phone number"
              onChange={(e) => mobileHandler(e.target.value)}
            />
          </div>
        </div>
        <div className="name_mail">
          <div class="div_1">
            <label for="" class="form-label">
              Address
            </label>
            <input
              type="text"
              value={fields.address}
              class="form-control"
              placeholder="Please enter your address"
              onChange={(e) => addressHandler(e.target.value)}
            />
          </div>
          <div class="div_1">
            <label for="" class="form-label">
              Gender
            </label>
            {/* <input
              value={fields.gender}
              type="text"
              class="form-control"
              placeholder="Please enter your gender"
              onChange={(e) => genderHandler(e.target.value)}
            /> */}
            <select
              value={fields.gender}
              className="form-control"
              onChange={(e) => genderHandler(e.target.value)}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
        </div>
        <div className="buttons">
          <button onClick={handleUpdate} id="btn1">
            Update Profile
          </button>
          <button id="btn2">Reset</button>
        </div>
      </div>
    </>
  );
}
