/* eslint-disable react-hooks/rules-of-hooks */
// UserContext.js
import React, { createContext, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

const UserContext = createContext();
  // const navigate = useNavigate();

const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const storedUserData = localStorage.getItem("userData");
  const [userDetails, setUserDetails] = useState({});


  useEffect(() => {
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
    const userInfo = JSON.parse(storedUserData);
    if (!userInfo) return;
    const getUserDetail = async () => {
      try {
        const myHeaders = new Headers();
        const userForm = new FormData();
        userForm.append("user_id", userInfo.userId);
        const res = await fetch(
          "https://useind.com/admin/API/AuthUser/getDetails",
          {
            method: "POST",
            headers: myHeaders,
            body: userForm,
          }
        );
        const data = await res.json();
        setUserDetails(data.data);
        // navigate("/");
      } catch (err) {
        console.log(err);
      }
    };

    getUserDetail();
  }, [storedUserData]);

  const login = (userData) => {
    localStorage.setItem("userData", JSON.stringify(userData));
    setUserData(userData);
  };

  const logout = () => {
    setUserData(null);
    localStorage.removeItem("userData");
  };

  const userUpdateHanlder = async (profileData) => {
    try {
      const myHeaders = new Headers();

      const res = await fetch(
        "https://useind.com/admin/API/AuthUser/UpdateProfile",
        {
          method: "POST",
          body: profileData,
          headers: myHeaders,
        }
      );

      const data = await res.json();
      const updatedData = data.data[0];
      setUserDetails(updatedData);
      
    } catch (err) {
      console.log(err);
    }
  };

  const profileUpdateHandler = async (profile_pic) => {
    try {
      const myHeaders = new Headers();

      const res = await fetch(
        "https://useind.com/admin/API/AuthUser/updateProfileImage",
        {
          method: "POST",
          headers: myHeaders,
          body: profile_pic,
        }
      );
      const data = await res.json();
      const updatedData = data.data[0];
      setUserDetails(updatedData);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <UserContext.Provider
      value={{
        userUpdateHanlder,
        userData,
        login,
        logout,
        userDetails,
        profileUpdateHandler,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
