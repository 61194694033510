import React from 'react';
import "./Useind_Price.css"
import check_icon from "./image/checkmark 1.png"
import check_icon_2 from "./image/checkmark 2.png"
import cross_icon from "./image/crossmark.png"
import bullet_icon from "./image/bullet.png"
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import close from "../contact/close.svg";
import close_2 from "../contact/Icon.png";

export default function Useind_Price_Comp() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div className="pricing_page">
            <div className="heading_section">
                <h2>Ready to get started?</h2>
            </div>
            <div className="pricing_package_section">
                <div className="card_base_div">
                    <div className="card_base">
                        <h3>Quarterly</h3>
                        <h4>For new users</h4>
                        <h2><span id="span_1">$</span>21.95<span id="span_2">/Month</span> </h2>
                        <h6><span id="span_3">$</span>65.83<span id="span_4"> (Billed Quarterly)</span> </h6>
                        <button className="btn_1" onClick={handleOpen}>GET STARTED</button>
                        <h5>Quarterly plan includes:</h5>
                        <div className="item"><img alt='#' src={check_icon}></img><h6>Direct Connect</h6></div>
                        <div className="item"><img alt='#' src={check_icon}></img><h6>Expert Assistance</h6></div>
                        <div className="item item_3"><img alt='#' src={check_icon}></img><h6>Security Assessment And Trouble Shooting (PC)</h6></div>
                        <div className="item"><img alt='#' src={check_icon}></img><h6>No Scam Alert Notifications </h6></div>
                        <div className="item"><img alt='#' src={check_icon}></img><h6>No Educational Resource</h6></div>
                    </div>
                    <button className="btn_2">Includes Scam identification Kit</button>
                </div>
                <div className="card_base_div card_pro_div">
                    <div className="card_base card_pro">
                        <h3>Half Yearly</h3>
                        <h4>For professionals</h4>
                        <h2><span id="span_1">$</span>18.95<span id="span_2">/Month</span> </h2>
                        <h6><span id="span_3">$</span>113.70<span id="span_4"> (Billed Half Yearly)</span> </h6>
                        <button className="btn_3" onClick={handleOpen}>Upgrade</button>
                        <h5>Half Yearly plan includes:</h5>
                        <div className="item"><img alt='#' src={check_icon}></img><h6>Direct Connect</h6></div>
                        <div className="item"><img alt='#' src={check_icon}></img><h6>Expert Assistance</h6></div>
                        <div className="item item_3"><img alt='#' src={check_icon}></img><h6>Security Assessment And Trouble Shooting (PC, Macbook, Tablet, Mobile)</h6></div>
                        <div className="item"><img alt='#' src={check_icon}></img><h6>Scam Alert Notifications </h6></div>
                        <div className="item"><img alt='#' src={check_icon}></img><h6>Educational Resource - ASM Worth $29.95</h6></div>
                    </div>
                    <button className="btn_4">Includes Preventive Anti-Scam Module</button>
                </div>
                <div className="card_base_div card_pro_2_div">
                    <div className="card_base card_pro_2">
                        <h3>Yearly</h3>
                        <h4>For professionals</h4>
                        <h2><span id="span_1">$</span>15.83<span id="span_2">/Month</span> </h2>
                        <h6><span id="span_3">$</span>189.85<span id="span_4"> (Billed Yearly)</span> </h6>
                        <button className="btn_3" onClick={handleOpen}>Upgrade</button>
                        <h5>Yearly plan includes:</h5>
                        <div className="item"><img alt='#' src={check_icon}></img><h6>Direct Connect</h6></div>
                        <div className="item"><img alt='#' src={check_icon}></img><h6>Expert Assistance</h6></div>
                        <div className="item item_3"><img alt='#' src={check_icon}></img><h6>Security Assessment And Trouble Shooting (2PC, Macbook, 3 Mobiles And Tablets)</h6></div>
                        <div className="item"><img alt='#' src={check_icon}></img><h6>Scam Alert Notifications </h6></div>
                        <div className="item"><img alt='#' src={check_icon}></img><h6>Educational Resource - ASM Worth $29.95</h6></div>
                    </div>
                    <button className="btn_6">Includes Preventive Anti-Scam Module</button>
                </div>
            </div>
            {/* <div className="text_below_pricing_section">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div> */}

            <div className="table_section">
                <table>
                    <thead>
                        <tr>
                            <th className="th_1">OVERVIEW</th>
                            <th className="th_2">QUARTERLY</th>
                            <th className="th_3">HALF YEARLY</th>
                            <th className="th_3">YEARLY</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="rows">
                            <td className="td_1">Direct Connect</td>
                            <td className="td_2"> <img className="check_icon_2" src={check_icon_2} /></td>
                            <td className="td_3"> <img className="check_icon_2" src={check_icon_2} /></td>
                            <td className="td_3"> <img className="check_icon_2" src={check_icon_2} /></td>
                        </tr>
                        <tr className="rows">
                            <td className="td_1">Expert Assistance</td>
                            <td className="td_2"> <img className="check_icon_2" src={check_icon_2} /></td>
                            <td className="td_3"> <img className="check_icon_2" src={check_icon_2} /></td>
                            <td className="td_3"> <img className="check_icon_2" src={check_icon_2} /></td>
                        </tr>
                        <tr className="rows">
                            <td className="td_1">Scam Alert Notifications</td>
                            <td className="td_2"> <img className="check_icon_2" src={cross_icon} /></td>
                            <td className="td_3"> <img className="check_icon_2" src={check_icon_2} /></td>
                            <td className="td_3"> <img className="check_icon_2" src={check_icon_2} /></td>
                        </tr>
                        <tr className="rows">
                            <td className="td_1">Security Assessment And Trouble Shooting</td>
                            <td className="td_2">PC | Macbook</td>
                            <td className="td_3">PC | Macbook | Tablet | Mobile</td>
                            <td className="td_3">2 PC | Macbook | 3 Mobiles And Tablets</td>
                        </tr>
                        <tr className="rows">
                            <td className="td_1">Educational Resources</td>
                            <td className="td_2"> <img className="check_icon_2" src={cross_icon} /></td>
                            <td className="td_3">ASM Worth $29.95</td>
                            <td className="td_3">ASM Worth $29.95</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="heading_section">
                <h3>Features at a glance</h3>
            </div>
            <div className="def_section">
                <table>
                    <tbody>
                        <tr className="rows">
                            <th> <img className='bullet_icon' src={bullet_icon} /> Direct Connect </th>
                            <td>Connecting customers to their desired destinations accurately.</td>
                        </tr>
                        <tr className="rows">
                            <th><img className='bullet_icon' src={bullet_icon} />Expert Assistance </th>
                            <td>Profound guidance from our knowledgeable professionals to detect and prevent scams.</td>
                        </tr>
                        <tr className="rows">
                            <th><img className='bullet_icon' src={bullet_icon} />Security Assessment And Trouble Shooting </th>
                            <td>Identifying, diagnosing and resolving errors or issues that arise with electronic gadgets such as computers, smartphones and tablets/Ipads.</td>
                        </tr>
                        <tr className="rows">
                            <th><img className='bullet_icon' src={bullet_icon} />Scam Alert Notifications</th>
                            <td>Subscribers will receive alerts about new scams circulating in their area or targeting specific demographics.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="login-signup-popup">
                        <div className="login-signup-popup-card">
                            <img className='cross_icon' src={close} alt="#" onClick={handleClose} />
                            <div className="login-signup-popup-card1-1">
                                <div className="login-signup-popup-card1">
                                    <form>
                                        <input
                                            className='first_name'
                                            type="text"
                                            placeholder="First Name..."
                                            required
                                        ></input>
                                        <input
                                            className='last_name'
                                            type="text"
                                            placeholder="Last Name..."
                                        ></input>
                                        <input
                                            className='email'
                                            type="email"
                                            placeholder=" Email..."
                                            required
                                        ></input>
                                        <input
                                            className='phone'
                                            type="text"
                                            placeholder=" Phone..."
                                        ></input>
                                        <select>
                                            <option>Quarterly</option>
                                            <option>Half Yearly</option>
                                            <option>Yearly</option>
                                        </select>
                                        <textarea placeholder='Message'></textarea>
                                        <input className='submitt_button'
                                            type="submit"
                                        ></input>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}
