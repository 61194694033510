import React, { useContext, useState } from "react";
import "./style.css";
import UseindNavbar from "../Useind--file/Useind_web_Navbar";
import UseindHeaderMobile from "../Useind--file/Useind_Mobile_Header";
import FooterMobile from "../Useind--file/Useind_Mobile_footer";
import UseindFooter from "../Useind--file/Useind_web_footer";
import Left_Nav from "./Left_Nav";
import { UserContext } from "../context/UserContext";

function PasswordUpdate() {
  const initialState = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const { userDetails } = useContext(UserContext);
  const [fields, setFields] = useState(initialState);

  const currentPasswordHandler = (value) => {
    setFields((prev) => {
      return { ...prev, currentPassword: value };
    });
  };

  const newPasswordHandler = (value) => {
    setFields((prev) => {
      return { ...prev, newPassword: value };
    });
  };

  const confirmPasswordHandler = (value) => {
    setFields((prev) => {
      return { ...prev, confirmPassword: value };
    });
  };

  const updatePasswordHandler = async () => {
    try {
      const myHeaders = new Headers();
      const userForm = new FormData();
      userForm.append("old_pass", fields.currentPassword);
      userForm.append("new_pass", fields.newPassword);
      userForm.append("confirm_pass", fields.confirmPassword);
      userForm.append("user_id", userDetails.id);

      const res = await fetch(
        "https://useind.com/admin/API/AuthUser/changePassword",
        {
          method: "POST",
          body: userForm,
          headers: myHeaders,
        }
      );

      const data = await res.json();
      console.log(data);
      if (data.status) {
        alert("Password updated successfully");
        setFields(initialState);
      } else {
        alert(data.msg);
      }
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="navbar_web">
        <UseindNavbar />
      </div>
      <div className="navbar_mobile">
        <UseindHeaderMobile />
      </div>

      <div className="container-fluid dashboard_home">
        <div className="row">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-12">
              <Left_Nav />
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-8 col-12">
              <div className="main">
                <h4>Update Password</h4>
                <hr />
                <div className="name_mail">
                  <div class="div_1">
                    <label for="" class="form-label">
                      Current Password
                    </label>
                    <input
                      value={fields.currentPassword}
                      onChange={(e) => currentPasswordHandler(e.target.value)}
                      type="text"
                      class="form-control"
                      placeholder="Please enter current password"
                    />
                  </div>
                </div>
                <div className="name_mail">
                  <div class="div_1">
                    <label for="" class="form-label">
                      New Password
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      value={fields.newPassword}
                      onChange={(e) => newPasswordHandler(e.target.value)}
                      placeholder="Please enter new password"
                    />
                  </div>
                </div>
                <div className="name_mail">
                  <div class="div_1">
                    <label for="" class="form-label">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      value={fields.confirmPassword}
                      onChange={(e) => confirmPasswordHandler(e.target.value)}
                      placeholder="Please enter confirm password"
                    />
                  </div>
                </div>
                <div className="buttons">
                  <button onClick={updatePasswordHandler} id="btn1">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_mobile">
        <FooterMobile />
      </div>
      <div className="footer_web">
        <UseindFooter />
      </div>
    </div>
  );
}

export default PasswordUpdate;
