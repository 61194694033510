import { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import { downloadPDF } from "./Useind--file/Useind_Homepage";

import "./Checkout.css";
import UseindNavbar from "./Useind--file/Useind_web_Navbar";
import UseindHeaderMobile from "./Useind--file/Useind_Mobile_Header";
import FooterMobile from "./Useind--file/Useind_Mobile_footer";
import UseindFooter from "./Useind--file/Useind_web_footer";
import Banner_web from "./Useind--file/image/usend desktop banner (1).png";
import Banner_mob from "./Useind--file/image/usend mob banner (1).png";
import bullet_icon from "./Useind--file/image/bullet.png";
import spam_protection_pdf from "./Useind--file/image/USEIND_ ASM.pdf";

const Checkout = () => {
  const [show, setShow] = useState(true);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const navigate = useNavigate();

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Buy the Protection file",
            amount: {
              currency_code: "USD",
              value: 29.95,
            },
          },
        ],
      })
      .then((orderID) => {
        setOrderID(orderID);

        return orderID;
      });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      console.log(payer);
      setSuccess(true);
    });
  };

  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
    document.getElementById("pay_NC").classList.remove("hide");
  };

  useEffect(() => {
    if (success) {
      alert("Payment successful!!");
      document.getElementById("pay_NC").classList.add("hide");
      document
        .getElementById("pay_Suc")
        .classList.remove("hide_successfull_payment");
      document
        .getElementById("pay_Suc_2")
        .classList.remove("hide_successfull_payment");
      document
        .getElementById("paypal_payment_button")
        .classList.add("hide_payment_option");
      // console.log("Order successful . Your order id is--", orderID);
      // navigate("/");
      setTimeout(() => {
        // setPaymentCompleted(true);
        downloadPDF();
      }, 2000); // Wait for 3 seconds before triggering the download
    }
  }, [success]);

  return (
    <>
      <div className="checkout_page">
        <div className="navbar_web">
          <UseindNavbar />
        </div>
        <div className="navbar_mobile">
          <UseindHeaderMobile />
        </div>
        <div className="banner_div">
          <img className="image1" src={Banner_web} alt="#" />
          <img className="image2" src={Banner_mob} alt="#" />
        </div>
        <div className="payment_btns">
          <div className="paypal_btn">
            <h3 id="pay_NC" className="hide">
              Payment was unsuccessful. Please make the payment from the link
              below to access Anti-Scam Module.
            </h3>
            <h3 id="pay_Suc" className="hide_successfull_payment">
              Payment Successful.
            </h3>
            <a
              id="pay_Suc_2"
              href={spam_protection_pdf}
              className="hide_successfull_payment"
            >
              <h4>Click here if your download hasn't started.</h4>
            </a>
            <PayPalScriptProvider
              options={{
                "client-id":
                  "AfL9CdGFMj-8uBR0lWzlaTCUTnbbI2qkvLOt5CD2hoEVgqrzCmOyBONiXdKb_YPbOtDU9yJZ0_74ckfj",
              }}
            >
              <div>
                {show ? (
                  <PayPalButtons
                    id="paypal_payment_button"
                    className=""
                    style={{ layout: "vertical" }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                    onError={onError}
                  />
                ) : null}
              </div>
            </PayPalScriptProvider>
          </div>
        </div>
        <div className="heading_section_outer">
          <div className="heading_section">
            <h3>
              Subscribe to USEIND Assist Plans to <br />
              avail these features
            </h3>
            <button
              onClick={() => {
                navigate("/Price");
              }}
            >
              Get Started
            </button>
          </div>

          <div className="def_section">
            <table>
              <tbody>
                <tr className="rows">
                  <th>
                    {" "}
                    <img className="bullet_icon" src={bullet_icon} /> Step 1:{" "}
                  </th>
                  <td>Verify Scams in history and records.</td>
                </tr>
                <tr className="rows">
                  <th>
                    <img className="bullet_icon" src={bullet_icon} />
                    Step 2:
                  </th>
                  <td>Get the right support for Hassle free Scam detection.</td>
                </tr>
                <tr className="rows">
                  <th>
                    <img className="bullet_icon" src={bullet_icon} />
                    Step 3:{" "}
                  </th>
                  <td>Stay ahead of threats before they reach your devices.</td>
                </tr>
                <tr className="rows">
                  <th>
                    <img className="bullet_icon" src={bullet_icon} />
                    Step 4:
                  </th>
                  <td>Share the threat assessments with people you know.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="footer_mobile">
          <FooterMobile />
        </div>
        <div className="footer_web">
          <UseindFooter />
        </div>
      </div>
    </>
  );
};
export default Checkout;
