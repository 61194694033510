import "./Useind.css";
import aboutbanner from "./image/aboutbanner.png";
import about1 from "./image/about1.png";
import about2 from "./image/about2.png";
import mapc from "./image/mapc.svg";

export default function UseindAbout() {
  return (
    <div>
      <div className="usind--about--us--page">
        <div className="Useind--about--us--content">
          <p>
            Lets make managing your networks, security and communication easier.
          </p>
          <img src={aboutbanner}></img>
        </div>
        <div className="Useind--about--us--content--1">
          <div className="Useind--about--us--content--1-1">
            <div className="Useind--about--us--content--1-2">
              <div className="Useind--about--us--content--1-21">
                <p className="Useind--about--us--content--1-2-p1">
                  Who we are?
                </p>
                <p className="Useind--about--us--content--1-2-p2">
                  Welcome to USEIND, your trusted ally in the ever-evolving
                  digital landscape. As pioneers in scam protection, we are
                  dedicated to ensuring your safety from online threats and
                  fraudulent schemes. With the exponential growth of digital
                  transactions and communication, safeguarding your personal and
                  financial information has never been more crucial.
                </p>
              </div>
              <div className="about-content-web-view">
                <div className="useind--about--card--1">
                  <div className="useind--about--card--1--1">
                    <img src={about1}></img>
                  </div>
                  <div className="useind--about--card--1--2">
                    <p className="Useind--about--us--content--1-2-p1-1">
                      Why USEIND?
                    </p>
                    <p className="useind--about--card--1--2--p">
                      At USEIND, we offer a comprehensive suite of tools,
                      resources, and expert guidance to empower you in
                      navigating the digital world securely. From phishing
                      emails to fake customer care, our vigilant team stays
                      ahead of emerging threats to provide you with the latest
                      information and proactive measures.
                    </p>
                  </div>
                </div>
                <div className="useind--about--card--1">
                  <div className="useind--about--card--1--2">
                    <p className="Useind--about--us--content--1-2-p1-1">
                      How USEIND protects you
                    </p>
                    <p className="useind--about--card--1--2--p">
                      Our platform delivers real-time alerts, scam detection
                      tips, educational content, and interactive tutorials,
                      equipping you with the knowledge and tools needed to
                      recognize and avoid scams effectively. Whether you're a
                      seasoned internet user or just starting online, our
                      user-friendly interface and personalized recommendations
                      make safeguarding your digital presence simple and
                      straightforward.
                    </p>
                  </div>
                  <div className="useind--about--card--1--1">
                    <img src={about2}></img>
                  </div>
                </div>
                <div className="useind--about--card--1">
                  <div className="useind--about--card--1--1">
                    <img src={mapc}></img>
                  </div>
                  <div className="useind--about--card--1--2">
                    <p className="Useind--about--us--content--1-2-p1-1">
                      USEIND X Ministry of External Affairs
                    </p>
                    <p className="useind--about--card--1--2--p">
                      At USEIND, your safety is our top priority. With our scam
                      protection website by your side, you can browse, shop, and
                      connect with confidence, knowing that we are committed to
                      protecting you every step of the way. Join us in our
                      mission to create a safer digital environment for all.
                    </p>
                  </div>
                </div>
              </div>

              <div className="about-content-mobile-view">
                <div className="useind--about--card--1">
                  <div className="useind--about--card--1--1">
                    <img src={about1}></img>
                  </div>
                  <div className="useind--about--card--1--2">
                    <p className="Useind--about--us--content--1-2-p1-1">
                      Why USEIND?
                    </p>
                    <p className="useind--about--card--1--2--p">
                      At USEIND, we offer a comprehensive suite of tools,
                      resources, and expert guidance to empower you in
                      navigating the digital world securely. From phishing
                      emails to fake customer care, our vigilant team stays
                      ahead of emerging threats to provide you with the latest
                      information and proactive measures.
                    </p>
                  </div>
                </div>
                <div className="useind--about--card--1">
                  <div className="useind--about--card--1--1">
                    <img src={about2}></img>
                  </div>
                  <div className="useind--about--card--1--2">
                    <p className="Useind--about--us--content--1-2-p1-1">
                      How USEIND protects you
                    </p>

                    <p className="useind--about--card--1--2--p">
                      Our platform delivers real-time alerts, scam detection
                      tips, educational content, and interactive tutorials,
                      equipping you with the knowledge and tools needed to
                      recognize and avoid scams effectively. Whether you're a
                      seasoned internet user or just starting online, our
                      user-friendly interface and personalized recommendations
                      make safeguarding your digital presence simple and
                      straightforward.
                    </p>
                  </div>
                </div>
                <div className="useind--about--card--1">
                  <div className="useind--about--card--1--1">
                    <img src={mapc}></img>
                  </div>
                  <div className="useind--about--card--1--2">
                    <p className="Useind--about--us--content--1-2-p1-1">
                      USEIND X Ministry of External Affairs
                    </p>
                    <p className="useind--about--card--1--2--p">
                      At USEIND, your safety is our top priority. With our scam
                      protection website by your side, you can browse, shop, and
                      connect with confidence, knowing that we are committed to
                      protecting you every step of the way. Join us in our
                      mission to create a safer digital environment for all.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
